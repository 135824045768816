import { useRouter } from 'next/router';
import { ReactNode, useMemo, useState } from 'react';

import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { SideBar } from '../../components/SideBar';
import CodeBlockSettingsContext, {
  CodeBlockLanguage,
} from '../../components/dynamicFlow/markdoc/CodeBlockSettings';
import { Navigation, NavigationItemOrGroup } from '../../types';

import { DFDocHead } from './DFDocHead';
import styles from './DynamicFlowApp.module.css';
import { legacySpecMenu, guidesMenu, featuresMenu, examplesMenu } from './manifest';
import { specMenuItems } from './spec-manifest';

const DynamicFlowApp = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const path = router.pathname;

  const sidebarMenus: Record<string, NavigationItemOrGroup[]> = {
    Features: featuresMenu,
    Guides: guidesMenu,
    Spec: specMenuItems,
    Examples: examplesMenu,
    'Legacy Spec': legacySpecMenu,
  };

  const navigationItems = [
    {
      title: 'Home',
      link: '/dynamic-flow',
      active: path?.endsWith('/public/dynamic-flow'),
    },
    {
      title: 'Features',
      link: '/dynamic-flow/features',
      active: path?.startsWith('/public/dynamic-flow/features'),
    },
    {
      title: 'Examples',
      link: '/dynamic-flow/examples',
      active: path?.startsWith('/public/dynamic-flow/examples'),
    },
    {
      title: 'Guides',
      link: '/dynamic-flow/guides',
      active: path?.startsWith('/public/dynamic-flow/guides'),
    },
    {
      title: 'Spec',
      link: '/dynamic-flow/docs',
      active: path?.startsWith('/public/dynamic-flow/docs'),
    },
    {
      title: 'Legacy Spec',
      link: '/dynamic-flow/legacy-docs',
      active: path?.startsWith('/public/dynamic-flow/legacy-docs'),
    },
    {
      title: 'Sandbox',
      link: '/dynamic-flow/sandbox',
      active: path?.startsWith('/public/dynamic-flow/sandbox'),
    },
  ];

  const activeItem = navigationItems.find((item) => item.active);

  const sidebarMenu = activeItem?.title ? sidebarMenus[activeItem.title] : undefined;

  const navigation: Navigation = {
    homeLink: '/dynamic-flow',
    title: 'Dynamic Flow',
    // Don't show legacy spec in the top nav
    items: navigationItems.filter((item) => item.title !== 'Legacy Spec'),
  };

  return (
    <>
      <DFDocHead />
      <div className="site-body dynamic-flow">
        <Header navigation={navigation} />
        <div className="site-container site-container--small">
          <ContentWrapper menu={sidebarMenu} className={styles.sidebarContainer + ' site-main'}>
            {children}
          </ContentWrapper>
        </div>
        <Footer navigation={navigation} />
      </div>
    </>
  );
};

const ContentWrapper = ({
  children,
  menu,
  className = '',
}: {
  children: ReactNode;
  path?: string;
  menu?: NavigationItemOrGroup[];
  className?: string;
}) => {
  const [language, setLanguage] = useState<CodeBlockLanguage>('kotlin');
  const [showFullExample, setShowFullExample] = useState<boolean>(false);

  const featureLanguage = useMemo(
    () => ({ language, setLanguage, showFullExample, setShowFullExample }),
    [language, setLanguage, showFullExample, setShowFullExample],
  );

  if (!menu) {
    return (
      <main className="site-main">
        <CodeBlockSettingsContext.Provider value={featureLanguage}>
          {children}
        </CodeBlockSettingsContext.Provider>
      </main>
    );
  }

  return (
    <div className={className}>
      <SideBar items={menu} />
      <main>
        <CodeBlockSettingsContext.Provider value={featureLanguage}>
          {children}
        </CodeBlockSettingsContext.Provider>
      </main>
    </div>
  );
};

export default DynamicFlowApp;
